@media (min-width: 768px) {
  .hp-privacy {
    width: 100vw;
    margin: auto;
    padding-bottom: 10px;
    overflow-y: hidden;
  }

  .hp-privacyTextContainer {
    margin-top: 80px;
  }

  .hp-privacyTitle {
    margin-bottom: 20px;
  }

  .hp-privacyTextContainer {
    z-index: 1;
    position: relative;
  }

  .hp-privacySecond {
    width: 90%;
    margin: auto auto 10px;
  }
}

@media (min-width: 1024px) {
  .non-desk {
    display: none;
  }

  .fadeText {
    font-size: 80px;
  }

  .hp-okSecondary, .vc-title, .vc-text {
    font-size: 15px;
  }

  .hp-brandCol > a:hover > div {
    transform: scale(1.05);
  }

  .hp-landText {
    width: calc(100vw - 20px);
    height: calc(100vh - 20px);
    border: 10px solid #f7f7f7;
  }

  .hp-born {
    height: 640px;
  }

  .hp-bornContainer {
    width: 65%;
    margin: 180px auto auto;
  }

  .hp-bornText {
    font-size: 35px;
  }

  .hp-ok {
    background-image: url("https://d35408cyocioye.cloudfront.net/new3/homepage/micro-bg2.jpg");
  }

  .hp-okContainer {
    margin-left: 180px;
  }

  .hp-vcTitle.hp-title {
    font-size: 80px;
  }

  .vc-container {
    flex-direction: row;
  }

  .hp-section.hp-voiceControl {
    height: 100vh;
  }

  .hp-privacy {
    background-image: url("https://d35408cyocioye.cloudfront.net/new3/homepage/privacy-bg3.jpg");
  }

  .banner-container {
    height: 100px;
    flex-direction: row;
    margin-top: 0;
  }

  .keynote-banner {
    width: 90%;
    max-width: 1100px;
    height: 100px;
    top: 90vh;
  }

  .banner-title {
    margin-left: 40px;
  }

  .banner-title span {
    letter-spacing: 4px;
    margin-left: 20px;
    font-size: 40px;
  }

  .banner-date {
    width: unset;
    margin-right: 40px;
  }

  .banner-date span {
    margin-bottom: 0;
    margin-right: 40px;
    display: inline-block;
  }

  .hp-JoshLogo {
    transform: scale(1);
  }

  .hp-landCopy {
    max-width: 700px;
    margin-bottom: 130px;
    font-size: 80px;
  }

  .hp-vid-container, .hp-intro-vid {
    width: 60vw;
    height: 33.75vw;
    max-width: none;
    top: 50%;
  }

  .hp-intro {
    height: 100vh;
  }

  .hp-introCopy {
    font-size: 40px;
  }

  .hp-introPlay {
    margin-top: 25px;
  }

  .hp-smallTextContainer.hp-designTextContainer {
    margin-bottom: 0;
  }

  .hp-section-small {
    height: 70vh;
  }

  .hp-smallContainer {
    flex-direction: row;
  }

  .hp-smallTextContainer {
    text-align: left;
    width: 50%;
  }

  .hp-btn {
    margin-bottom: 0;
  }

  .hp-topMicro {
    width: 400px;
    height: 400px;
  }

  .circ5 {
    margin-top: -150px;
    margin-left: 450px;
  }

  .circ0 {
    opacity: 0;
    margin-top: -250px;
    margin-left: 350px;
  }

  .circ1 {
    opacity: 0;
    margin-top: -400px;
    margin-left: 200px;
  }

  .circ2 {
    opacity: 0;
    margin-top: -550px;
    margin-left: 50px;
  }

  .circ3 {
    opacity: 0;
    margin-top: -700px;
    margin-left: -100px;
    display: block;
  }

  .hp-title {
    font-size: 80px;
  }

  .hp-secondary {
    max-width: 700px;
  }

  .hp-brands {
    height: calc(70vh + 280px);
  }

  .hp-ai {
    margin-bottom: 0;
  }

  .hp-brandSecond {
    width: 50%;
    margin-bottom: 60px;
  }

  .hp-brandCol {
    flex-direction: column;
    display: flex;
  }

  .hp-mobile-brandCol {
    display: none;
  }

  .hp-aiTitle {
    letter-spacing: 0;
    max-width: 1000px;
    margin-top: 120px;
    font-family: CGBold;
    font-size: 80px;
    font-weight: bold;
    line-height: .94;
  }

  .hp-blurbContainer {
    flex-direction: row;
  }

  .hp-aiPoint {
    margin-bottom: 0;
  }

  .ai-secondary {
    width: 320px;
    max-width: none;
    font-size: 15px;
  }

  .blurb-title, .blurb-copy {
    font-size: 15px;
  }

  .hp-sideMicro {
    height: 400px;
    max-width: none;
    position: absolute;
    left: 40%;
    transform: translateY(-25%);
    width: auto !important;
  }

  .hp-designTitle, .hp-appTitle {
    width: 40vw;
    max-width: 520px;
    margin-bottom: 40px;
  }

  .hp-design {
    height: 90vh;
    background-image: url("https://joshai.s3.amazonaws.com/new3/homepage/micro-dark-on-surface.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .hp-app {
    height: 90vh;
    margin-top: -100px;
  }

  .hp-appContainer {
    margin-top: 0;
  }

  .hp-aiPointsContainer {
    width: 95vw;
    flex-direction: row;
  }

  .hp-aiContainer {
    padding-bottom: 60px;
  }

  .hp-appImgContainer {
    max-width: 400px;
    transform: translateY(5%);
  }

  .hp-privacy {
    width: 100vw;
    margin: auto;
    padding-bottom: 0;
    overflow-y: hidden;
  }

  .hp-privacyTextContainer {
    margin-top: 80px;
    padding-top: 50px;
  }

  .hp-privacyTitle {
    margin-bottom: 20px;
  }

  .hp-privacyTextContainer {
    z-index: 1;
    position: relative;
  }

  .hp-privacySecond {
    width: 60%;
    margin: auto;
  }

  .next-flex {
    flex-direction: row;
  }

  .next-item {
    margin-right: 80px;
  }
}

@media (min-width: 1124px) {
  .hp-aiPointsContainer {
    width: 85vw;
  }
}

@media (min-width: 1440px) {
  .hp-designTitle {
    width: 35vw;
  }
}

/*# sourceMappingURL=index.d961a86a.css.map */
